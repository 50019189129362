<template>
  <div>
    <div id="divPrompt" class="prompt-input-container">
      <div class="prompt">
        <h1>AI提示语</h1>
        <p>根据方案的模板,填写AI提示语Prompt</p>
      </div>
      <div class="input-box">
        <t-textarea
            ref="prompt"
            v-model="inputText" 
            placeholder="请输入内容..." 
            class="input-area"
            autosize 
            status="success"
            tips="AI大模型的提示语，尽量精炼"
        />
      </div>
      <br>
      <div class="prompt">
        <p>AI解析结果</p>
      </div>
      <div class="input-box">
        <t-textarea
            ref="result"
            v-model="inputResult" 
            placeholder="请输入内容..." 
            class="input-area"
            autosize 
            status="success"
            tips="后续使用到的节点，必须有值"
        />
      </div>
      <br>
      <div style="text-align: center;">
        <t-button theme="primary" @click="nextPage">
          <add-icon slot="icon" />下一步
        </t-button>  
        <Menu />      
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
import { MessagePlugin ,Button as TButton,Textarea as TTextarea} from 'tdesign-vue';
import { AddIcon,EditIcon } from 'tdesign-icons-vue';  
import Menu from './Menu.vue';
import requestAPI from '../js/ServerAPI.js'
export default {
  name: 'PromptInput',
  components: { MessagePlugin,TTextarea,TButton,AddIcon,EditIcon,Menu},
  data() {
    return {
      inputText : '' , // 用于双向绑定的输入文本
      inputResult : '' ,
      schemaid:''
    };
  },
  async created() {
    const postData = {
      solution: this.$route.params.solution,
    }
    requestAPI(this.$postUrl+'/aischema/get', postData, (response) => {
      this.schemaid = response.id;
      this.inputText = response.prompt;
      this.inputResult = response.result;
    });
  },
  methods: {
    analysis(){
      this.$refs.result.value = "over!";
    },
    async nextPage(){
      if(this.$refs.prompt.value == ""){
        MessagePlugin.error("提示语不能为空");
        this.$refs.prompt.focus();
        return;
      }
      if(this.$refs.result.value == ""){
        MessagePlugin.error("解析结果不能为空");
        this.$refs.result.focus();
        return;
      }

      const postData = {
        id: this.schemaid,
        solution: this.$route.params.solution,
        prompt:this.inputText,
        result:this.inputResult
      }
      
      let url ;
      if(this.schemaid == null)
        url = '/aischema/add'
      else
        url = '/aischema/update'

      requestAPI(this.$postUrl+url, postData, (response) => {
        return response;
      });    
      
      this.$router.push(
      {
        name: 'TagFilter',
        params: {
          solution: this.$route.params.solution,
          result: this.inputResult
        }
      });
    },
  },
};
</script>

<style scoped>
.prompt-input-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* 添加渐变背景 */
}

.prompt {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.prompt h1 {
  color: #333;
  font-size: 24px;
}

.prompt p {
  color: #666;
  font-size: 16px;
}

.input-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.input-area {
  width: 100%;
  min-height: 150px;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  resize: vertical; /* 允许垂直方向的调整大小 */
}

.input-area:focus {
  border: 1px solid #ccc;
}
</style>