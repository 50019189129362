import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import TagFilter from '../views/TagFilter.vue'
import TagSource from '../views/TagSource.vue'
import VchTemlate from '../views/VchTemplate.vue'
import Prompt from '../views/Prompt.vue'
import AISchemaList from '../views/SolutionList.vue'
import AsstMapping from '../views/mapping/AsstMapping.vue'
import UserUploadPC from '../views/FileUploadPC.vue'
import UserLogin from '../views/UserLogin.vue'
import Menu from '../views/Menu.vue';

Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/SolutionList',
    name: 'SolutionList',
    component: () => import('../views/SolutionList.vue')
  },
  {
    path: '/Menu',
    name: 'Menu',
    component: () => import('../views/Menu.vue')
  },
  {
    path: '/Prompt',
    name: 'Prompt',
    component: () => import('../views/Prompt.vue')
  },
  {
    path: '/TagFilter',
    name: 'TagFilter',
    component: () => import('../views/TagFilter.vue')
  },
  {
    path: '/TagSource',
    name: 'TagSource',
    component: () => import('../views/TagSource.vue')
  },
  {
    path: '/BaseSource',
    name: 'BaseSource',
    component: () => import('../views/BaseSource.vue')
  },
  {
    path: '/VchTemplate',
    name: 'VchTemplate',
    component: () => import('../views/VchTemplate.vue')
  },
  {
    path: '/AsstMapping',
    name: 'AsstMapping',
    component: () => import('../views/mapping/AsstMapping.vue')
  },
  {
    path: '/UserLogin',
    name: 'UserLogin',
    component: () => import('../views/UserLogin.vue')
  },
  {
    path: '/FileUploadPC',
    name: 'FileUploadPC',
    component: () => import('../views/FileUploadPC.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
