<template>
  <div class="login-container">
    <el-card class="login-card">
      <h2 class="login-title">登录</h2>
      <el-form :model="loginForm" :rules="loginRules" ref="loginFormRef">
        <el-form-item label="企业ID" prop="enterpriseID">
          <el-input v-model="loginForm.enterprise" auto-complete="on" @keyup.enter.native="focusUser" placeholder="请输入企业ID"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input ref="userInput" v-model="loginForm.username" auto-complete="on" @keyup.enter.native="focusPassword" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input ref="passwordInput" type="password" v-model="loginForm.password" @keyup.enter.native="handleSubmit"  placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <router-view></router-view>
  </div>
</template>

<script>
import { Card, Form, Input, Button,FormItem} from 'element-ui';
import { MessagePlugin } from 'tdesign-vue';
  
  export default {
    components: {
      [Card.name]: Card,
      [Form.name]: Form,
      [Input.name]: Input,
      [Button.name]: Button,
      [FormItem.name]:FormItem
    },
    data() {
      return {
        loginForm: {
          enterprise: localStorage.getItem("enterprise"),
          username: localStorage.getItem("username"),
          password: '',
        },
        loginRules: {
          enterprise:[
            { required: true, message: '请输入企业ID', trigger: 'blur' },
          ],
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      focusUser(){
        this.$refs.userInput.focus();
      },
      focusPassword() {  
        this.$refs.passwordInput.focus();
      },
      async sendRequest() {
        const postHeader = {
          headers: {
            'Content-Type': 'application/json'
          }
        }
        const postData = {
          enterprise: this.loginForm.enterprise,
          username: this.loginForm.username,
          password: this.loginForm.password
        }

        let url = this.$postUrl+'/login/valid';
            // 获取Token
        const response = 
              await this.$axios
              .post(url, postData, postHeader)
              .then(response => {
                  return response.data                      
              })
              .catch(error => {
                console.log(error)
              })
        return response;
      },
      async handleSubmit() {
        const response = await this.sendRequest();

        if(response == null || response.id == null) {
          MessagePlugin.error('登录失败');
          return;
        }      

        if(response.id != null)
          localStorage.setItem('enterprise', response.enterprise);
          localStorage.setItem('username', response.username);
          MessagePlugin.success(response.username+'欢迎回来');
        
        this.$router.push(
        {
          name: 'SolutionList',
          params: {
            enterprise: response.enterprise
          },
          query: {
            token:response.token
          }
        });
      }
    }
  };
</script>

<style>
.login-container {
  display: flex;
  flex-direction: column;  
  align-items: center;  
  justify-content: center;  
  height: 90%;  
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* 添加渐变背景 */
}

.login-card {
  width: 400px;
  border-radius: 10px; /* 圆角边框 */
  background: #fff; /* 白色背景 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  padding: 30px; /* 内部填充 */
}

.login-title {
  text-align: center;
  color: #333; /* 字体颜色 */
  margin-bottom: 20px; /* 标题与表单间距 */
}

/* 调整表单元素样式 */
.el-form-item__label {
  color: #666; /* 标签字体颜色 */
}

.el-input__inner {
  border-radius: 4px; /* 输入框圆角 */
}

.el-button {
  width: 100%; /* 按钮宽度 */
  border-radius: 20px; /* 按钮圆角 */
  font-weight: bold; /* 字体加粗 */
  letter-spacing: 2px; /* 字间距 */
}

/* 响应式设计 */
@media (max-width: 600px) {
  .login-card {
    width: 90%;
  }
}
</style>