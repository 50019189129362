<template>
  <div class="app-container">
    <div class="header">
      <h2>本方案条件选择</h2>
    </div>
    <div class="container">
      <div class="left-content">
        <t-space direction="vertical">
          <t-tree
            :data="items"
            hover
            :expand-mutex=true
            :expand-on-click-node=false
            :transition=true
            :line=true
            @click="onNodeSelected"
          />
        </t-space>
      </div>
      <div class="vertical-separator"></div>
      <div class="right-content">
        <el-table :data="tableData">
          <el-table-column prop="seq" label="序号" width="50px"></el-table-column>
          <el-table-column label="备注" width="100px">    
            <template slot-scope="scope">    
              <t-tagInput v-model="scope.row.notes" autoWidth clearable :tag-props="{ theme: 'success' }" />  
            </template>    
          </el-table-column>  
          <el-table-column prop="node" label="节点" width="110px"></el-table-column>
          <el-table-column label="运算符" width="110px">
            <template slot-scope="scope">
              <t-select v-model="scope.row.sign" :options="options" placeholder="请选择" />
            </template>
          </el-table-column>
          <el-table-column label="标签">    
            <template slot-scope="scope" width="150px">  
              <t-space direction="vertical" style="width: 100%">
                <t-tagInput v-model="scope.row.tags" clearable :tag-props="{ theme: 'success' }" />  
              </t-space> 
            </template>    
          </el-table-column>
          <!-- 操作列 -->  
          <el-table-column label="操作" width="80px">  
            <template slot-scope="scope">  
              <el-button @click="handleDelete(scope.$index, scope.row)">删除</el-button>  
            </template>  
          </el-table-column> 
        </el-table>
        <br>
        <div style="text-align: center;">
          <t-button theme="primary" @click="nextPage">
            <add-icon slot="icon" />下一步
          </t-button>        
        </div>
      </div>
    </div>
    <Menu /> 
  </div>
</template>

<script>
  import Vue from 'vue';
  import ElementUI from 'element-ui'
  import Menu from './Menu.vue';
  import 'element-ui/lib/theme-chalk/index.css'
  import jsonToTree from '../js/InitTree.js';
  import requestAPI from '../js/ServerAPI.js'
  import { Button as TButton,TagInput as TTagInput,Table as TTable,Space as TSpace,Tree as TTree ,Select as TSelect} from 'tdesign-vue';
  import { AddIcon } from 'tdesign-icons-vue';  
  // 引入组件库的少量全局样式变量
  import 'tdesign-vue/es/style/index.css';

  Vue.use(ElementUI);

  export default {
    components: {
      TTagInput,TButton,TTable,TSpace,TTree,AddIcon,Menu ,TSelect
    },
    data() {
      return {
        items: [],
        tableData:[],
        isBase:false,
        options:[{ label: '包含', value: 0 }, { label: '不包含', value: 1 }, { label: '为空', value: 2 }, { label: '不为空', value: 3 }]
      }
    },

    async created() {

      this.items = jsonToTree(JSON.parse(this.$route.params.result));

      let postData = {
        solution: this.$route.params.solution,
      }
      requestAPI(this.$postUrl+'/tagFilter/list', postData, (response) => {
        if(response != null){
            // 使用map函数遍历数组，并对每个对象执行操作
            response = response.map(option => {
              // 创建一个新的notes数组，只包含note值
              option.notes = option.notes.map(note => note.note);
              option.tags = option.tags.map(tag => tag.tag);
              // 返回修改后的对象
              return option;
            });
            this.tableData = response;
        }
      });

      postData = {
        id: this.$route.params.solution,
      }
      requestAPI(this.$postUrl+'/solution/getBillType', postData, (response) => {
        this.isBase = response.base;
      });

    },
    methods: {
      handleDelete(index, row) {
        this.tableData.splice(index, 1);
      },
      onNodeSelected(context) {
        const { node } = context;
        var pathlist = node.getPath().map(item => item.label);
        var totalPath = pathlist.join('.');
        if(node.isLeaf()){
          const newrow = {
            solution: this.$route.params.solution,
            seq: this.tableData.length+1,
            node: totalPath, 
            notes: [],
            tags: [],
          }
          this.tableData.push(newrow);
        };
      },
      nextPage(){
        let data = this.tableData;
        let reversedata = data.map((item, itemIndex) => {
          const tagsWithIndex = item.tags.map((tag, tagIndex) => {
            return { seq: tagIndex, tag: tag };
          });
          const notesWithIndex = item.notes.map((note, noteIndex) => {
            return { seq: noteIndex, note: note };
          });
          return {
            ...item,
            tags: tagsWithIndex,
            notes: notesWithIndex
          };
        })

        const postData = {
          models : reversedata,
        }

        requestAPI(this.$postUrl+'/tagFilter/batchUpdate', postData, (response) => {
          return response;
        });

        if(this.isBase == undefined){
           alert('请重新登陆')
           return
        }
        
        let routername;
        if(this.isBase)
          routername = 'BaseSource';
        else
          routername = 'TagSource';
        
        this.$router.push(
        {
          name: routername,
          params: {
            solution: this.$route.params.solution,
            result: this.$route.params.result,
          }
        }); 
      },
    }
  }
</script>

<style>
.app-container {
  background: linear-gradient(to right, #6dd5ed, #2193b0);
  padding: 20px;
  height: 80%; 
}

.header h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.container {
  display: flex;
}

.left-content {
  flex: 0 0 20%; 
  padding: 5px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.right-content {
  flex: 1;
  margin-left: 20px;
}

.vertical-separator {
  border-left: 1px solid #ccc;
  height: 90%;
  margin: 0 5px;
}

.table-card, .tree-card {
  overflow: auto;
}

.action-buttons {
  margin-top: 20px;
}

/* 其他样式根据需要添加 */
</style>