import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$postUrl = process.env.VUE_APP_API_URL;

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.title = 'A2智能账务';
  }
}).$mount('#app')
